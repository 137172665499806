import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Services.css';


export default class About extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <section id="services" className="g-py-80">
                <div className="container">
                    <div className="text-uppercase text-center u-heading-v2-4--bottom g-brd-primary g-mb-80">
                        <h4 className="h6 g-font-weight-800 g-font-size-12 g-letter-spacing-2 g-color-primary g-mb-20">Our services</h4>
                        <h2 className="h1 u-heading-v2__title g-line-height-1_3 g-font-weight-600 g-font-size-40 g-mb-minus-10">What we do</h2>
                    </div>
            <div className="row no-gutters">
                        <div className="col-md-6 col-lg-3 g-parent g-brd-around g-brd-gray-light-v4 g-brd-bottom-primary--hover g-brd-bottom-2--hover g-mb-30 g-mb-0--lg g-transition-0_2 g-transition--ease-in">
                    <div className="text-center g-px-10 g-px-30--lg g-py-40 g-pt-25--parent-hover g-transition-0_2 g-transition--ease-in">
                                <span className="d-block g-color-primary g-font-size-40 g-mb-15">
                                    <i className="icon-fire"></i>
                                </span>
                                <h3 className="h5 text-uppercase g-color-black g-mb-10">Data Services</h3>
                                <p className="g-color-gray-dark-v4">Data dominates the business world. We offer services to help you in all aspects of data.</p>

                                <div className="d-inline-block g-width-40 g-brd-bottom g-brd-2 g-brd-primary g-my-15"></div>

                                <ul className="list-unstyled text-uppercase g-mb-0">
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Data Modeling</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Database Design</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">AI Solutions</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Data Management</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Reporting</li>
                                    <li className="g-py-8">Analytics</li>
                                </ul>
                            </div>
                </div>

                        <div className="col-md-6 col-lg-3 g-parent g-brd-around g-brd-gray-light-v4 g-brd-bottom-primary--hover g-brd-bottom-2--hover g-mb-30 g-mb-0--lg g-ml-minus-1 g-transition-0_2 g-transition--ease-in">
                    <div className="text-center g-px-10 g-px-30--lg g-py-40 g-pt-25--parent-hover g-transition-0_2 g-transition--ease-in">
                                <span className="d-block g-color-primary g-font-size-40 g-mb-15">
                                    <i className="icon-energy"></i>
                                </span>
                                <h3 className="h5 text-uppercase g-color-black g-mb-10">Custom Development</h3>
                                <p className="g-color-gray-dark-v4">Custom software solutions for all business needs. Bring your enterprise to its next level.</p>

                                <div className="d-inline-block g-width-40 g-brd-bottom g-brd-2 g-brd-primary g-my-15"></div>

                                <ul className="list-unstyled text-uppercase g-mb-0">
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Mobile Applications</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Web APIs</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Automation</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Security</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Cloud Solutions</li>
                                    <li className="g-py-8">System Integrations</li>
                                </ul>
                            </div>
                </div>

                        <div className="col-md-6 col-lg-3 g-parent g-brd-around g-brd-gray-light-v4 g-brd-bottom-primary--hover g-brd-bottom-2--hover g-mb-30 g-mb-0--lg g-ml-minus-1 g-transition-0_2 g-transition--ease-in">
                    <div className="text-center g-px-10 g-px-30--lg g-py-40 g-pt-25--parent-hover g-transition-0_2 g-transition--ease-in">
                                <span className="d-block g-color-primary g-font-size-40 g-mb-15">
                                    <i className="icon-layers"></i>
                                </span>
                                <h3 className="h5 text-uppercase g-color-black g-mb-10">Websites</h3>
                                <p className="g-color-gray-dark-v4">From creating your web presence to internal dashboard and client portals, we got it covered.</p>

                                <div className="d-inline-block g-width-40 g-brd-bottom g-brd-2 g-brd-primary g-my-15"></div>

                                <ul className="list-unstyled text-uppercase g-mb-0">
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Dashboards</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Marketing</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">E-commerce</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Responsive Web Desgin</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Portals</li>
                                    <li className="g-py-8">Social Networking</li>
                                </ul>
                            </div>
                </div>

                        <div className="col-md-6 col-lg-3 g-parent g-brd-around g-brd-gray-light-v4 g-brd-bottom-primary--hover g-brd-bottom-2--hover g-mb-30 g-mb-0--lg g-ml-minus-1 g-transition-0_2 g-transition--ease-in">
                    <div className="text-center g-px-10 g-px-30--lg g-py-40 g-pt-25--parent-hover g-transition-0_2 g-transition--ease-in">
                                <span className="d-block g-color-primary g-font-size-40 g-mb-15">
                                    <i className="icon-social-youtube"></i>
                                </span>
                                <h3 className="h5 text-uppercase g-color-black g-mb-10">Hosting</h3>
                                <p className="g-color-gray-dark-v4">Using modern Amazon AWS solutions we can find a home for all of your solutions.</p>

                                <div className="d-inline-block g-width-40 g-brd-bottom g-brd-2 g-brd-primary g-my-15"></div>

                                <ul className="list-unstyled text-uppercase g-mb-0">
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Storage</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Web Hosting</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Databases</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Job Servers</li>
                                    <li className="g-brd-bottom g-brd-gray-light-v3 g-py-10">Workstations</li>
                                    <li className="g-py-8">Load balanced</li>
                                </ul>
                            </div>
                </div>
                    </div>
        </div>
            </section>

        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
