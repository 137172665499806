import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Processes.css';

export default class About extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <section id="processes" className="g-theme-bg-blue-dark-v1 g-py-80">
                <div className="container text-center g-width-780 g-color-gray-light-v2 g-mb-60">
                    <div className="text-uppercase u-heading-v2-4--bottom g-brd-primary g-mb-40">
                        <h4 className="h6 g-font-weight-800 g-font-size-12 g-letter-spacing-2 g-color-primary g-mb-20">Work process</h4>
                        <h2 className="h1 u-heading-v2__title g-line-height-1_3 g-font-weight-600 g-font-size-40 g-color-white g-mb-minus-10">Step by step</h2>
                    </div>

                    <p className="g-color-gray-light-v2 mb-0"></p>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 g-px-30 g-mb-40">
                            <div className="g-pos-rel g-parent g-theme-bg-blue-dark-v2 g-py-35 g-px-25 g-pl-70--sm">
                                <div className="g-hidden-xs-down g-absolute-centered--y g-left-0">
                                    <div className="g-pull-50x-left g-brd-around g-brd-5 g-brd-primary-dark-v3 g-rounded-50x g-overflow-hidden">
                                        <span className="d-block g-pos-abs g-top-0 g-left-0 g-width-95 g-height-95 g-brd-around g-brd-5 g-brd-primary g-rounded-50x background-image1" ></span>
                                        <span className="u-icon-v3 u-icon-size--xl g-width-95 g-height-95 g-color-white g-bg-primary g-opacity-1 opacity-0--parent-hover g-transition-0_1 g-transition--ease-in">
                                            <i className="icon-fire"></i>
                                        </span>
                                    </div>
                                </div>

                                <h3 className="h5 text-uppercase g-font-size-16 g-color-gray-light-v2 g-mb-10">Step 1. Analisys</h3>
                                <p className="g-color-gray-light-v2 mb-0">Let's meet and see what your needs are. Here we begin to gather requirements and determine what solutions would be best for your business.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 g-px-30 g-mb-40">
                            <div className="g-pos-rel g-parent g-theme-bg-blue-dark-v2 g-py-35 g-px-25 g-pl-70--sm">
                                <div className="g-hidden-xs-down g-absolute-centered--y g-left-0">
                                    <div className="g-pull-50x-left g-brd-around g-brd-5 g-brd-primary-dark-v3 g-rounded-50x g-overflow-hidden">
                                        <span className="d-block g-pos-abs g-top-0 g-left-0 g-width-95 g-height-95 g-brd-around g-brd-5 g-brd-primary g-rounded-50x background-image2"></span>
                                        <span className="u-icon-v3 u-icon-size--xl g-width-95 g-height-95 g-color-white g-bg-primary g-opacity-1 opacity-0--parent-hover g-transition-0_1 g-transition--ease-in">
                                            <i className="icon-energy"></i>
                                        </span>
                                    </div>
                                </div>

                                <h3 className="h5 text-uppercase g-font-size-16 g-color-gray-light-v2 g-mb-10">Step 2. Design &amp; Prototype</h3>
                                <p className="g-color-gray-light-v2 mb-0">Once we have gathered requirements we can design and prototype the technical details of your software solution. This will allow you to visualize what the final product will be.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-mb-50">
                        <div className="col-lg-6 g-px-30 g-mb-40">
                            <div className="g-pos-rel g-parent g-theme-bg-blue-dark-v2 g-py-35 g-px-25 g-pl-70--sm">
                                <div className="g-hidden-xs-down g-absolute-centered--y g-left-0">
                                    <div className="g-pull-50x-left g-brd-around g-brd-5 g-brd-primary-dark-v3 g-rounded-50x g-overflow-hidden">
                                        <span className="d-block g-pos-abs g-top-0 g-left-0 g-width-95 g-height-95 g-brd-around g-brd-5 g-brd-primary g-rounded-50x background-image3"></span>
                                        <span className="u-icon-v3 u-icon-size--xl g-width-95 g-height-95 g-color-white g-bg-primary g-opacity-1 opacity-0--parent-hover g-transition-0_1 g-transition--ease-in">
                                            <i className="icon-rocket"></i>
                                        </span>
                                    </div>
                                </div>

                                <h3 className="h5 text-uppercase g-font-size-16 g-color-gray-light-v2 g-mb-10">Step 3. Development</h3>
                                <p className="g-color-gray-light-v2 mb-0">After we have agreed on a prototype we put our heads down and start to craft your solution. We will have milestone updates and demos along the way.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 g-px-30 g-mb-40">

                            <div className="g-pos-rel g-parent g-theme-bg-blue-dark-v2 g-py-35 g-px-25 g-pl-70--sm">
                                <div className="g-hidden-xs-down g-absolute-centered--y g-left-0">
                                    <div className="g-pull-50x-left g-brd-around g-brd-5 g-brd-primary-dark-v3 g-rounded-50x g-overflow-hidden">
                                        <span className="d-block g-pos-abs g-top-0 g-left-0 g-width-95 g-height-95 g-brd-around g-brd-5 g-brd-primary g-rounded-50x background-image4" ></span>
                                        <span className="u-icon-v3 u-icon-size--xl g-width-95 g-height-95 g-color-white g-bg-primary g-opacity-1 opacity-0--parent-hover g-transition-0_1 g-transition--ease-in">
                                            <i className="icon-layers"></i>
                                        </span>
                                    </div>
                                </div>

                                <h3 className="h5 text-uppercase g-font-size-16 g-color-gray-light-v2 g-mb-10">Step 4. Demo &amp; Support</h3>
                                <p className="g-color-gray-light-v2 mb-0">After development provide you with a demo of your solution and necessary support to ensure a smooth integration into your business processes.</p>
                            </div>

                        </div>
                    </div>


                    
                </div>
            </section>

        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
