import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

import logo from '../assets/img/logo.png'

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header id="js-header" className="u-header u-header--sticky-top u-header--change-appearance nav-menu" data-header-fix-moment="100">
                <div className="u-header__section g-theme-bg-blue-dark-v1-opacity-0_9 g-transition-0_3 g-py-25" data-header-fix-moment-exclude="g-py-25" data-header-fix-moment-classes="g-py-20">
                    <nav className="navbar navbar-expand-lg py-0">
                        <div className="container g-pos-rel">
                            <a href="#" className="js-go-to navbar-brand u-header__logo" data-type="static">
                                <img className="u-header__logo-img u-header__logo-img--main" src={logo} alt="Image Description"/>
                            </a>
                            <div className="collapse navbar-collapse align-items-center flex-sm-row" id="navBar" data-mobile-scroll-hide="true">
                                <ul id="js-scroll-nav" className="navbar-nav text-uppercase g-letter-spacing-2 g-font-size-11 g-pt-20 g-pt-0--lg ml-auto">
                                    <li className="nav-item g-mr-10--lg g-mr-15--xl g-mb-7 g-mb-0--lg active">
                                        <a href="#home" className="nav-link g-color-white p-0">Home <span className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item g-mx-10--lg g-mx-15--xl g-mb-7 g-mb-0--lg">
                                        <a href="#about" className="nav-link g-color-white p-0">About</a>
                                    </li>
                                    <li className="nav-item g-mx-10--lg g-mx-15--xl g-mb-7 g-mb-0--lg">
                                        <a href="#services" className="nav-link g-color-white p-0">Services</a>
                                    </li>
                                    <li className="nav-item g-mx-10--lg g-mx-15--xl g-mb-7 g-mb-0--lg">
                                        <a href="#processes" className="nav-link g-color-white p-0">Processes</a>
                                    </li>
                                    <li className="nav-item g-ml-10--lg g-ml-15--xl">
                                        <a href="#contact" className="nav-link g-color-white p-0">Contact</a>
                                    </li>
                                </ul>
                            </div>
                                <button className="navbar-toggler btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-10 g-right-0" type="button" aria-label="Toggle navigation"
                                    aria-expanded="false"
                                    aria-controls="navBar"
                                    data-toggle="collapse"
                                    data-target="#navBar">
                                    <span className="hamburger hamburger--slider">
                                        <span className="hamburger-box">
                                            <span className="hamburger-inner"></span>
                                        </span>
                                    </span>
                            </button>
                            </div>
                        </nav>
                    </div>
                </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
