import * as React from 'react';
import { connect } from 'react-redux';
import Promo from './Promo'
import About from './About'
import Services from './Services'
import Processes from './Processes'
import Clients from './Clients'


export default class Home extends React.Component<{}, {}, any> {
    componentDidMount() {  
       
    }

    public render() {
        return(
            <React.Fragment>
                <Promo />
                <About />
                <Services />
                <Processes />
                <Clients />

            </React.Fragment>
            )
    }
}

