import * as React from 'react';
import MyGoogleMap from './GoogleMap'

export default class Footer extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <footer>
                <div id="contact" className="text-center g-color-gray-light-v2 g-theme-bg-blue-dark-v1 g-py-80">
                    <div className="container g-width-780 g-mb-60">
                        <div className="text-uppercase u-heading-v2-4--bottom g-brd-primary g-mb-45">
                            <h4 className="h6 g-font-weight-800 g-font-size-12 g-letter-spacing-2 g-color-primary g-mb-20">Contact</h4>
                            <h2 className="h1 u-heading-v2__title g-line-height-1_3 g-font-weight-600 g-font-size-40 g-color-gray-light-v2 g-mb-minus-10">Our contacts</h2>
                        </div>

                        <p className="g-color-gray-light-v2 mb-0">We are always available. Email us today to discuss what solutions we can provide to your business.</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-3 g-brd-top g-brd-top-none g-brd-primary g-px-15 g-py-30 g-py-0--md">
                                <h3 className="text-uppercase g-font-size-default g-color-white-opacity-0_5 g-letter-spacing-1 g-mb-5"></h3>
                                <strong className="g-font-size-default g-color-white"></strong>
                            </div>

                            <div className="col-sm-6 col-md-3 g-px-15 g-py-30 g-py-0--md">
                                <i className="icon-globe d-inline-block g-font-size-50 g-color-primary g-mb-20"></i>
                                <h3 className="text-uppercase g-font-size-default g-color-white-opacity-0_5 g-letter-spacing-1 g-mb-5">Address</h3>
                                <strong className="g-font-size-default g-color-white">Tampa, FL</strong>
                            </div>                           

                            <div className="col-sm-6 col-md-3 g-brd-top g-brd-top-none g-brd-left--md g-brd-primary g-px-15 g-py-30 g-py-0--md">
                                <i className="icon-envelope d-inline-block g-font-size-50 g-color-primary g-mb-20"></i>
                                <h3 className="text-uppercase g-font-size-default g-color-white-opacity-0_5 g-letter-spacing-1 g-mb-5">Email</h3>
                                <a className="g-font-size-default g-color-white" href="mailto:sales@tuttitech.com"><strong>sales@tuttitech.com</strong></a>
                            </div>

                            <div className="col-sm-6 col-md-3 g-brd-top g-brd-top-none g-brd-primary g-px-15 g-py-30 g-py-0--md">                               
                                <h3 className="text-uppercase g-font-size-default g-color-white-opacity-0_5 g-letter-spacing-1 g-mb-5"></h3>
                                <strong className="g-font-size-default g-color-white"></strong>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className="row no-gutters list-inline g-mb-0">
                    <li className="col list-inline-item g-mr-0">
                        <a className="d-block g-theme-bg-blue-dark-v3 g-color-gray-dark-v5 g-bg-primary--hover g-color-white--hover g-font-size-default text-center g-pa-15" href="#">
                            <i className="fa fa-twitter"></i>
                        </a>
                    </li>
                    <li className="col list-inline-item g-mr-0">
                        <a className="d-block g-theme-bg-blue-dark-v2 g-color-gray-dark-v5 g-bg-primary--hover g-color-white--hover g-font-size-default text-center g-pa-15" href="#">
                            <i className="fa fa-linkedin"></i>
                        </a>
                    </li>
                    <li className="col list-inline-item g-mr-0">
                        <a className="d-block g-theme-bg-blue-dark-v3 g-color-gray-dark-v5 g-bg-primary--hover g-color-white--hover g-font-size-default text-center g-pa-15" href="#">
                            <i className="fa fa-facebook"></i>
                        </a>
                    </li>
                    <li className="col list-inline-item g-mr-0">
                        <a className="d-block g-theme-bg-blue-dark-v2 g-color-gray-dark-v5 g-bg-primary--hover g-color-white--hover g-font-size-default text-center g-pa-15" href="#">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </li>
                    <li className="col list-inline-item">
                        <a className="d-block g-theme-bg-blue-dark-v3 g-color-gray-dark-v5 g-bg-primary--hover g-color-white--hover g-font-size-default text-center g-pa-15" href="#">
                            <i className="fa fa-google-plus"></i>
                        </a>
                    </li>
                </ul>
                <div className="g-pos-rel g-height-500">
                    <MyGoogleMap />
                </div>
                <div className="text-center g-color-gray-dark-v3 g-pa-30">
                    <div className="g-width-600 mx-auto">
                        <h4 className="g-font-size-17 g-font-weight-400 g-letter-spacing-1 g-mb-15">
                            subscribe
                    <span className="text-uppercase">on our offers</span>
                        </h4>

                        <form>
                            <div className="form-group g-pos-rel g-mb-15">
                                <input className="form-control h-100 g-placeholder-gray-light-v1 g-font-size-default g-color-gray-dark-v3 g-placeholder-inherit g-brd-gray-light-v4 g-brd-gray-light-v4--focus rounded-0 g-pa-12 g-pr-40" type="text" placeholder="Enter Your Email"/>

                                    <div className="g-pos-abs g-top-0 g-right-0 g-flex-centered g-width-40 h-100 g-color-gray-light-v1">
                                        <i className="icon-envelope"></i>
                                    </div>
                    </div>
                </form>

                            <p className="g-font-size-12 mb-0">
                                &copy; 2019 All right reserved. Unify developed by
                    <a href="#" className="g-color-primary">Htmlstream</a>
                            </p>
            </div>
                    </div>
    </footer>

        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
