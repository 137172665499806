import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';


import './assets/vendor/bootstrap/bootstrap.min.css'
import './assets/vendor/icon-awesome/css/font-awesome.min.css'
import './assets/vendor/icon-line/css/simple-line-icons.css'
import './assets/vendor/icon-hs/style.css'
import './assets/vendor/hamburgers/hamburgers.min.css'
import './assets/vendor/animate.css'
import './assets/vendor/slick-carousel/slick/slick.css'
import './assets/css/styles.op-business.css'
import './assets/css/custom.css'

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
    </Layout>
);
