import * as React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from 'react-google-maps'
import markerPin from "../assets/img/pin.png";

const dataStyles = [
    {
        featureType: "",
        elementType: "",
        stylers:[{ "saturation": -100 }, { "lightness": 51 }, { "visibility": "simplified" }]
    },
    {
        featureType: "", elementType: "labels",
        stylers:[{ "visibility": "on" }]
    },
    { featureType: "water", elementType: "", stylers: [{ "color": "#1d1a37" }] }
]

const styles = {
    width: '100%',
    height: '100%'
}



const GoogleMapComponentWithMarker = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{
                lat: 27.94752, // latitude for the center of the map
                lng: -82.45843 // longitude for the center of the map
            }}
            defaultOptions={{
                disableDefaultUI: true, // disable default map UI
                draggable: true, // make map draggable
                keyboardShortcuts: false, // disable keyboard shortcuts
                scaleControl: false, // allow scale controle
                scrollwheel: false, // allow scroll wheel
                styles: dataStyles // change default map styles
            }}
        >
            <Marker
                icon={markerPin}
                position={{
                    lat: 27.94752, // latitude to position the marker
                    lng: -82.45843 // longitude to position the marker
                }}
            />
        </GoogleMap>
    ))
)

class MyGoogleMap extends React.PureComponent {
    render() {
        return (
            <div style={styles}>
                <GoogleMapComponentWithMarker
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCxyaDwrf0bQ-SfbuGBYV1BwxhVsIB83BM"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        )
    }
}

/*
class MyGoogleMap extends React.Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '100%', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key:"AIzaSyCxyaDwrf0bQ-SfbuGBYV1BwxhVsIB83BM" }}
                    defaultCenter={{ lat: 40.674, lng: -73.946}}
                    defaultZoom={12}              
                    defaultOptions={{
                        disableDefaultUI: true, // disable default map UI
                        draggable: true, // make map draggable
                        keyboardShortcuts: false, // disable keyboard shortcuts
                        scaleControl: true, // allow scale controle
                        scrollwheel: true, // allow scroll wheel
                        styles: styles // change default map styles
                    }}
                >
                    <Marker
                        icon={{
                            url: "../assets/img/pin.png" }}
                        position={{
                            lat: 40.674, // latitude to position the marker
                            lng: -73.946 // longitude to position the marker
                        }}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}
*/
export default MyGoogleMap;