import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Services.css';

import oesllp from '../assets/img/oesllp.png';
import efme from '../assets/img/efme.logo.png';
import fac from '../assets/img/fac-cap.png';
import hps from '../assets/img/hps-logo.png';
import se from '../assets/img/se-logo.png';



export default class About extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <section className="text-center g-py-80">
                <div className="container">
                    <div className="container g-width-780 g-mb-60">
                        <div className="text-uppercase u-heading-v2-4--bottom g-brd-primary g-mb-45">
                            <h4 className="h6 g-font-weight-800 g-font-size-12 g-letter-spacing-2 g-color-primary g-mb-20">Our clients</h4>
                            <h2 className="h1 u-heading-v2__title g-line-height-1_3 g-font-weight-600 g-font-size-40 g-mb-minus-10">Who work with us</h2>
                        </div>
                    </div>

                    <div>
                        <img className="img-fluid g-max-width-170--md mx-auto" src={oesllp} alt="Image Description" />
                        <img className="img-fluid g-max-width-170--md mx-auto" src={efme} alt="Image Description" />
                        <img className="img-fluid g-max-width-170--md mx-auto" src={fac} alt="Image Description" />
                        <img className="img-fluid g-max-width-170--md mx-auto" src={hps} alt="Image Description" />
                        <img className="img-fluid g-max-width-170--md mx-auto" src={se} alt="Image Description" />
                    </div>
                </div>
            </section>

        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
