import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './About.css';

import image2 from '../assets/img/450x300.jpg'

export default class About extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <section id="about" className="container-fluid px-0">
                <div className="row no-gutters">
                    <div className="g-hidden-md-down col-lg-4 g-bg-img-hero background-1"></div>

                    <div className="col-md-6 col-lg-4 g-flex-centered g-theme-bg-blue-dark-v1">
                        <div className="text-center g-color-gray-light-v2 g-pa-30">
                            <div className="text-uppercase u-heading-v2-4--bottom g-brd-primary g-mb-40">
                                <h4 className="h6 g-font-weight-800 g-font-size-12 g-letter-spacing-2 g-color-primary g-mb-20">About us</h4>
                                <h2 className="h1 u-heading-v2__title g-line-height-1_3 g-font-weight-600 g-font-size-40 g-color-white g-mb-minus-10">
                                    Modern Business
                                    <br/> Solutions
                                </h2>
                            </div>
                            <p className="g-color-gray-light-v2 mb-0">
                                    In a world ran by databases, analytics, mobile applications, websites, SEO, Face Snaps and Insta Pics, having technology is paramount to a business' survival. Today's business' lean on the foundation of software and technology that drive them. In order to bring your business to the next level, you need the technology to go along with it. That is what TuttiTech provides; software solutions for the small and enterprise business world.

                            </p>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 g-theme-bg-blue-dark-v2 history">
                        <div>
                            <img className="img-fluid w-100" src={image2} alt="Image description" />
                        </div>
                        <div className="copy-container">
                            <h3 className="text-uppercase g-font-weight-700 g-font-size-20 g-color-white g-mb-10">Since 2017</h3>
                            <p className="g-color-gray-light-v2 g-mb-10">
                                We have been creating software solutions to propel businesses to the next level. Our expertise ranges from creating mobile applications for both iOS and Android, fully functional multi-tier web portal solutions, impressive simple page responsive web pages, database design, reporting dashboard, and anyaltics
                            </p>
                        </div>                            
                    </div>
                </div>
    </section>

        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
