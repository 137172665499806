import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Promo.css';

import video from '../assets/video/background-video.mp4'

export default class Promo extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public componentDidMount() {

    }

    public render() {
        return (
            <section id="home" className="g-pos-rel">
                <video className="main-background" autoPlay muted loop id="myVideo">
                    <source src={video} type="video/mp4"/>
                </video>
                <div id="mobile-background" className="main-background"></div>
                <div id="video-overlay" className="video-overlay"></div>
             </section>

        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
